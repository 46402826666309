import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/8NqDOaw0O8Q">
    <SEO title="Imago Dei - Counter Culture" />
  </Layout>
)

export default SermonPost
